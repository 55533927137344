import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      title: "登录"
    },
    hidden: true,
    component: () => import("@/views/login/login.vue")
  },
  {
    path: "/navigation",
    name: "navigation",
    meta: {
      title: "导航"
    },
    hidden: true,
    component: () => import("@/views/login/navigation.vue")
  },
  {
    path: "/navigation1",
    name: "navigation1",
    meta: {
      title: "导航1"
    },
    hidden: true,
    component: () => import("@/views/login/navigation1.vue")
  },
  {
    path: "/index",
    name: "index",
    hidden: false,
    meta: {
      title: "首页"
    },
    redirect: "/index/industry/index/population",
    component: () => import("@/views/layout/index.vue"),
    children: [
      {
        path: "industry",
        name: "industry",
        meta: {
          title: "行业管理",
          icon: "el-icon-s-tools",
          index: "1"
        },
        redirect: "/index/industry/index/population",
        component: () => import("@/views/industry/index.vue"),
        // children: [
        //   {
        //     path: "index",
        //     name: "index",
        //     meta: {
        //       title: "行业概况",
        //       icon: "mail",
        //       index: "1-1",
        //       to: "/index/industry/index/population"
        //     },
        //     component: () => import("@/views/industry/index.vue"),
        //
        //   }
        // ]
        children: [
          {
            path: "index/population",
            name: "population",
            meta: {
              title: "基本概况",
              index: "1-1-1",
              to: "/index/industry/index/population"
            },
            component: () => import("@/views/industry/population/index.vue")
          },
          {
            hide: true,
            path: "index/detail",
            name: "detail",
            meta: {
              title: "基本概况-详情",
              index: "1-1-2",
              to: "/index/industry/index/detail"
            },
            component: () => import("@/views/industry/population/detail.vue")
          },
          {
            path: "index/rates",
            name: "rates",
            meta: {
              title: "四率",
              index: "1-1-2",
              to: "/index/industry/index/rates"
            },
            component: () => import("@/views/industry/rates/index.vue")
          },
          {
            path: "index/focus",
            name: "focus",
            meta: {
              title: "集中供水工程",
              index: "1-2-1",
              to: "/index/industry/index/focus"
            },
            component: () => import("@/views/industry/focus/index.vue")
          },
          {
            hide: true,
            path: "index/focusDetail",
            name: "focusDetail",
            meta: {
              title: "集中供水工程详表",
              index: "1-2-2",
              to: "/index/industry/index/focusDetail"
            },
            component: () => import("@/views/industry/focus/detail.vue")
          },
          {
            path: "/base/center",
            name: "BaseCenter",
            meta: {
              title: "检测中心管理",
              index: "3-1-3",
              permission: ["base.center"]
            },
            component: () => import("@/views/base/CenterList.vue")
          }
        ]
      },
      {
        path: "waterQuality",
        name: "waterQuality",
        meta: {
          title: "水质管理",
          icon: "experiment",
          index: "2"
        },
        redirect: "/index/waterQuality/index/survey",
        component: () => import("@/views/waterQuality/index.vue"),
        // children: [
        //   {
        //     path: "index",
        //     name: "index",
        //     meta: {
        //       title: "水质概况",
        //       icon: "experiment",
        //       index: "2-1",
        //       to: "/index/waterQuality/index/survey"
        //     },
        //     component: () => import("@/views/waterQuality/index.vue"),
        //
        //   }
        // ]
        children: [
          {
            path: "index/survey",
            name: "survey",
            meta: {
              title: "水质概况",
              index: "2-1-1",
              to: "/index/waterQuality/index/survey"
            },
            component: () => import("@/views/waterQuality/survey/index.vue")
          },
          {
            hide: true,
            path: "index/detail",
            name: "detail",
            meta: {
              title: "水质概况-详情",
              index: "2-1-2",
              to: "/index/waterQuality/index/detail"
            },
            component: () => import("@/views/waterQuality/survey/detail.vue")
          },
          {
            path: "index/analysis",
            name: "analysis",
            meta: {
              title: "超标分析",
              index: "2-1-1",
              to: "/index/waterQuality/index/analysis"
            },
            component: () => import("@/views/waterQuality/analysis/index.vue")
          },
          {
            hide: true,
            path: "index/analysisDetail",
            name: "analysisDetail",
            meta: {
              title: "超标分析-详表",
              index: "2-1-1",
              to: "/index/waterQuality/index/analysisDetail"
            },
            component: () => import("@/views/waterQuality/analysis/detail.vue")
          },
          {
            path: "index/testReport",
            name: "testReport",
            meta: {
              title: "检测报告",
              index: "2-1-1",
              to: "/index/waterQuality/index/testReport"
            },
            component: () => import("@/views/waterQuality/testReport/index.vue")
          },
          {
            path: "index/testReportDetail",
            name: "testReportDetail",
            meta: {
              title: "报告查询",
              index: "2-1-1",
              to: "/index/waterQuality/index/testReportDetail"
            },
            component: () =>
              import("@/views/waterQuality/testReport/detail.vue")
          }
        ]
      },

      {
        path: "submit",
        name: "submit",
        meta: {
          title: "数据填报",
          icon: "el-icon-s-tools",
          index: "3"
        },
        redirect: "/index/submit/base",
        component: () => import("@/views/base/index.vue"),
        children: [
          {
            path: "base",
            name: "base",
            meta: {
              title: "基础数据填报",
              index: "3-1",
              to: "/index/submit/base",
              permission: ["base"]
            },
            component: () => import("@/views/base/index.vue"),
            children: [
              // {
              //   path: "/base/center",
              //   name: "BaseCenter",
              //   meta: {
              //     title: "检测中心管理",
              //     index: "3-1-3",
              //     permission: ["base.center"]
              //   },
              //   component: () => import("@/views/base/CenterList.vue")
              // },
              {
                path: "/base/center/edit",
                name: "BaseCenterEdit",
                component: () => import("@/views/base/CenterEdit.vue"),
                meta: {
                  title: "检测中心编辑",
                  keepAlive: true,
                  permission: ["base.center.edit"]
                },
                hidden: true
              },
              {
                path: "/base/menu",
                name: "BaseMenu",
                meta: {
                  title: "菜单管理",
                  index: "3-1-1",
                  permission: ["base.menu"]
                },
                component: () => import("@/views/base/MenuList.vue")
              },
              {
                path: "/base/menu/edit",
                name: "BaseMenuEdit",
                component: () => import("@/views/base/MenuEdit.vue"),
                meta: {
                  title: "菜单编辑",
                  keepAlive: true,
                  permission: ["base.menu.edit"]
                },
                hidden: true
              },
              {
                path: "/base/notice",
                name: "BaseNotice",
                meta: {
                  title: "通知管理",
                  index: "3-1-4",
                  permission: ["base.menu"]
                },
                component: () => import("@/views/base/NoticeList.vue")
              },
              {
                path: "/base/notice/edit",
                name: "BaseNoticeEdit",
                component: () => import("@/views/base/NoticeEdit.vue"),
                meta: {
                  title: "通知编辑",
                  keepAlive: true,
                  permission: ["base.menu.edit"]
                },
                hidden: true
              },
              {
                path: "/base/notice/view",
                name: "BaseNoticeView",
                component: () => import("@/views/base/NoticeView.vue"),
                meta: {
                  title: "通知查看",
                  keepAlive: true,
                  permission: ["base.menu.edit"]
                },
                hidden: true
              },
              {
                path: "/base/doc",
                name: "BaseDoc",
                meta: {
                  title: "资料管理",
                  index: "3-1-5",
                  permission: ["base.doc"]
                },
                component: () => import("@/views/base/DocList.vue")
              },
              {
                path: "/base/doc/edit",
                name: "BaseDocEdit",
                component: () => import("@/views/base/DocEdit.vue"),
                meta: {
                  title: "资料编辑",
                  keepAlive: true,
                  permission: ["base.doc.edit"]
                },
                hidden: true
              },
              {
                path: "/base/doc/viewList",
                name: "BaseDocViewList",
                component: () => import("@/views/base/DocViewList.vue"),
                meta: {
                  title: "资料查看",
                  keepAlive: true,
                  permission: ["base.doc.list"]
                },
                hidden: true
              },
              {
                path: "/base/doc/viewSpecial",
                name: "BaseDocViewSpecial",
                component: () => import("@/views/base/DocViewSpecialList.vue"),
                meta: {
                  title: "资料查看",
                  keepAlive: true,
                  permission: ["base.doc.list"]
                },
                hidden: true
              },
              {
                path: "/base/doc/view",
                name: "BaseDocView",
                component: () => import("@/views/base/DocView.vue"),
                meta: {
                  title: "资料查看",
                  keepAlive: true,
                  permission: ["base.doc.view"]
                },
                hidden: true
              },
              {
                path: "/base/doc/special",
                name: "BaseDocSpecial",
                component: () => import("@/views/base/DocViewSpecial.vue"),
                meta: {
                  title: "资料查看",
                  keepAlive: true,
                  permission: ["base.doc.view"]
                },
                hidden: true
              },
              {
                path: "/test/item",
                name: "TestItem",
                meta: {
                  title: "检测项管理",
                  index: "3-1-2",
                  to: "/test/item",
                  permission: ["test.item"]
                },
                component: () => import("@/views/test/ItemList.vue")
              },
              {
                path: "/test/item/edit",
                name: "TestItemEdit",
                component: () => import("@/views/test/ItemEdit"),
                meta: {
                  title: "检测项目编辑",
                  keepAlive: true,
                  permission: ["test.item.edit"]
                },
                hidden: true
              },
              {
                path: "/test/method",
                name: "TestMethod",
                component: () => import("@/views/test/MethodList"),
                meta: {
                  title: "检测方法管理",
                  keepAlive: true,
                  permission: ["test.method"]
                },
                hidden: true
              },
              {
                path: "/test/method/edit",
                name: "TestMethodEdit",
                component: () => import("@/views/test/MethodEdit"),
                meta: {
                  title: "检测方法编辑",
                  keepAlive: true,
                  permission: ["test.method.edit"]
                },
                hidden: true
              }
            ]
          },
          {
            path: "quality",
            name: "quality",
            meta: {
              title: "水质检测",
              index: "3-2"
            },
            component: () => import("@/views/test/index.vue"),
            children: [
              {
                path: "/test/report",
                name: "TestReport",
                meta: {
                  title: "检测报告填报",
                  index: "3-2-1",
                  permission: ["test.report"]
                },
                component: () => import("@/views/test/ReportList.vue")
              },
              {
                path: "/test/report/view",
                name: "TestReportView",
                component: () => import("@/views/test/ReportView.vue"),
                meta: {
                  title: "水质检测报告查看",
                  index: "3-2-1",
                  permission: ["test.report.view"]
                },
                hidden: true
              },
              {
                path: "/test/report/edit",
                name: "TestReportEdit",
                component: () => import("@/views/test/ReportEdit.vue"),
                meta: {
                  title: "水质检测报告编辑",
                  keepAlive: true,
                  permission: ["test.report.edit"]
                },
                hidden: true
              },
              {
                path: "/test/report/sample",
                name: "TestReportSample",
                component: () => import("@/views/test/ReportSample.vue"),
                meta: {
                  title: "水质检测报告水样编辑",
                  keepAlive: true,
                  permission: ["test.report.sample"]
                },
                hidden: true
              },
              {
                path: "/test/setting",
                name: "TestSetting",
                meta: {
                  title: "快速检测项管理",
                  index: "3-2-2",
                  permission: ["test.setting"]
                },
                component: () => import("@/views/test/Setting.vue")
              },
              {
                path: "/test/setting/view",
                name: "TestSettingView",
                component: () => import("@/views/test/SettingView"),
                meta: {
                  title: "水质检测查看",
                  keepAlive: true,
                  permission: ["test.setting.view"]
                },
                hidden: true
              },
              {
                path: "/test/setting/edit",
                name: "TestSettingEdit",
                component: () => import("@/views/test/SettingEdit"),
                meta: {
                  title: "水质检测快速录入",
                  keepAlive: true,
                  permission: ["test.setting.edit"]
                },
                hidden: true
              }
            ]
          }
        ]
      },

      {
        path: "monitor",
        name: "monitor",
        meta: {
          title: "水厂监测",
          icon: "experiment",
          index: "4"
        },
        redirect: "/index/monitor/survey",
        component: () => import("@/views/monitor/index.vue"),
        children: [
          {
            path: "survey",
            name: "survey",
            meta: {
              title: "运行概况",
              index: "4-1-1",
              to: "/index/monitor/survey"
            },
            component: () => import("@/views/monitor/survey.vue")
          },
          {
            path: "monitoring",
            name: "monitoring",
            meta: {
              title: "水厂监控",
              index: "4-1-2",
              to: "/index/monitor/monitoring"
            },
            component: () => import("@/views/monitor/monitoring.vue")
          },
          {
            path: "supervise",
            name: "supervise",
            meta: {
              title: "管网监测",
              index: "4-1-3",
              to: "/index/monitor/supervise"
            },
            component: () => import("@/views/monitor/supervise.vue")
          },
          {
            path: "supply",
            name: "supply",
            meta: {
              title: "供水查询",
              index: "4-1-4",
              to: "/index/monitor/supply"
            },
            component: () => import("@/views/monitor/monitor.vue")
          },
          {
            path: "qualityquery",
            name: "qualityquery",
            meta: {
              title: "水质查询",
              index: "4-1-5",
              to: "/index/monitor/qualityquery"
            },
            component: () => import("@/views/monitor/qualityquery.vue")
          },
          {
            path: "alarm",
            name: "alarm",
            meta: {
              title: "报警记录",
              index: "4-1-6",
              to: "/index/monitor/alarm"
            },
            component: () => import("@/views/monitor/alarm.vue")
          },
          {
            path: "contacts",
            name: "contacts",
            meta: {
              title: "报警联系人",
              index: "4-1-7",
              to: "/index/monitor/contacts"
            },
            component: () => import("@/views/monitor/contacts.vue")
          },
          {
            path: "device",
            name: "device",
            meta: {
              title: "设备列表",
              index: "4-1-8",
              to: "/index/monitor/device"
            },
            component: () => import("@/views/monitor/device.vue")
          },
          {
            path: "set",
            name: "set",
            meta: {
              title: "数据设置",
              index: "4-1-13",
              to: "/index/monitor/set"
            },
            component: () => import("@/views/monitor/set.vue")
          },
          {
            path: "camera",
            name: "camera",
            meta: {
              title: "视频安防",
              index: "4-1-9",
              to: "/index/monitor/camera"
            },
            component: () => import("@/views/monitor/camera.vue")
          },
          {
            path: "leakquery",
            name: "leakquery",
            meta: {
              title: "漏损查询",
              index: "4-1-10",
              to: "/index/monitor/leakquery"
            },
            component: () => import("@/views/monitor/leakquery.vue")
          },
          {
            path: "leakanalysis",
            name: "leakanalysis",
            meta: {
              title: "漏损分析",
              index: "4-1-10",
              to: "/index/monitor/leakanalysis"
            },
            component: () => import("@/views/monitor/leakanalysis.vue")
          },
          {
            path: "pipecheck",
            name: "pipecheck",
            meta: {
              title: "管网考核",
              index: "4-1-11",
              to: "/index/monitor/pipecheck"
            },
            component: () => import("@/views/monitor/pipecheck.vue")
          },
          {
            path: "pipequery",
            name: "pipequery",
            meta: {
              title: "水量查询",
              index: "4-1-12",
              to: "/index/monitor/pipequery"
            },
            component: () => import("@/views/monitor/pipequery.vue")
          },
          {
            path: "quality",
            name: "quality",
            meta: {
              title: "水质监测",
              index: "4-1-13",
              to: "/index/monitor/quality"
            },
            component: () => import("@/views/monitor/quality.vue")
          },
          {
            path: "zutai",
            name: "zutai",
            meta: {
              title: "组态监测",
              index: "4-1-5",
              to: "/index/monitor/zutai"
            },
            component: () => import("@/views/monitor/zutai.vue")
          }
        ]
      },

      {
        path: "auth",
        name: "auth",
        meta: {
          title: "用户管理",
          icon: "el-icon-s-tools",
          index: "6"
        },
        redirect: "/index/auth/users",
        component: () => import("@/views/auth/index.vue"),
        children: [
          {
            path: "users",
            name: "users",
            meta: {
              title: "用户管理",
              index: "6-1",
              to: "/index/auth/users"
            },
            component: () => import("@/views/auth/users/index.vue"),
            children: [
              {
                path: "usersList",
                name: "usersList",
                meta: {
                  title: "用户列表",
                  index: "6-1-1",
                  to: "/index/auth/users/usersList"
                },
                component: () => import("@/views/auth/users/usersList.vue")
              },
              {
                path: "createUsers",
                name: "createUsers",
                meta: {
                  title: "新建用户",
                  index: "6-1-2",
                  to: "/index/auth/users/createUsers"
                },
                component: () => import("@/views/auth/users/create.vue")
              }
            ]
          },
          {
            path: "role",
            name: "role",
            meta: {
              title: "角色管理",
              index: "6-2",
              to: "/index/auth/role"
            },
            component: () => import("@/views/auth/role/index.vue"),
            children: [
              {
                path: "roleList",
                name: "roleList",
                meta: {
                  title: "角色列表",
                  index: "6-2-1",
                  to: "/index/auth/role/roleList"
                },
                component: () => import("@/views/auth/role/roleList.vue")
              },
              {
                path: "createRole",
                name: "createRole",
                meta: {
                  title: "新建角色",
                  index: "6-2-2",
                  to: "/index/auth/role/createRole"
                },
                component: () => import("@/views/auth/role/create.vue")
              }
            ]
          },
          {
            path: "organization",
            name: "organization",
            meta: {
              title: "组织管理",
              index: "6-3",
              to: "/index/auth/organization"
            },
            component: () => import("@/views/auth/organization/index.vue"),
            children: [
              {
                path: "organizationList",
                name: "organizationList",
                meta: {
                  title: "组织列表",
                  index: "6-3-1",
                  to: "/index/auth/organization/organizationList"
                },
                component: () =>
                  import("@/views/auth/organization/organizationList.vue")
              },
              {
                path: "createOrganization",
                name: "createOrganization",
                meta: {
                  title: "新建组织",
                  index: "6-3-2",
                  to: "/index/auth/organization/createOrganization"
                },
                component: () => import("@/views/auth/organization/create.vue")
              }
            ]
          },
          {
            path: "menu",
            name: "menu",
            meta: {
              title: "菜单管理",
              index: "6-4",
              to: "/index/auth/menu"
            },
            component: () => import("@/views/auth/menu/index.vue"),
            children: [
              {
                path: "menuManagement",
                name: "menuManagement",
                meta: {
                  title: "菜单管理",
                  index: "6-4-1",
                  to: "/index/auth/menu/menuManagement"
                },
                component: () => import("@/views/auth/menu/menu.vue")
              },
              {
                path: "revise",
                name: "revise",
                meta: {
                  title: "修改系统名称",
                  index: "6-4-2",
                  to: "/index/auth/menu/revise"
                },
                component: () => import("@/views/auth/menu/revise.vue")
              }
            ]
          }
        ]
      },
      {
        path: "/index/users",
        name: "userspw",
        meta: {
          title: "修改密码"
        },
        hidden: false,
        component: () => import("@/views/users/my/changePassword.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  // base: "/water/",
  routes
});

router.beforeEach((to, from, next) => {
  console.log(to);
  //判断是否需要登录
  let token = localStorage.getItem("token");
  if (to.path == "/") {
    next();
  } else {
    if (token) {
      next();
    } else {
      next({
        name: "login"
        // query: {
        //   redirect: to.fullPath
        // }
      });
    }
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
export default router;
