import Vue from "vue";
import Vuex from "vuex";
// import getters from "./getters";
// import user from "./module/user";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    navNum: "",
    system_name: "",
    system_logo: "",
    menus: [],
    user: { router: {} }
  },
  getters: {
    getMenus: state => {
      return state.menus;
    },
    getRouters: state => {
      return state.user.router;
    },
    getSystemName: state => {
      state.system_name = localStorage.getItem("system_name");
      return state.system_name;
    },
    getSystemLogo: state => {
      state.system_logo = localStorage.getItem("system_logo");
      return state.system_logo;
    },
    getNavNum: state => {
      return state.navNum;
    }
  },
  mutations: {
    setMenus(state, value) {
      state.menus = value;
    },
    setRouters(state, value) {
      state.user.router = value;
    },
    setSystemName(state, value) {
      state.system_name = value;
      localStorage.setItem("system_name", value);
    },
    setSystemLogo(state, value) {
      state.system_logo = value;
      localStorage.setItem("system_logo", value);
    },
    setNavNum(state, value) {
      state.navNum = value;
      localStorage.setItem("navNum", value);
    }
  },
  actions: {}
});
export default store;
