import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from './locales'
import "./global.less";


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);
import Button from "ant-design-vue/lib/button";
Vue.component(Button.name, Button);
//注入全局属性$message
import { message } from "ant-design-vue";
Vue.prototype.$message = message;
message.config({
  duration: 2, // 持续时间
  top: `100px`, // 到页面顶部距离
  maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});

import echarts from "echarts";
Vue.prototype.$echarts = echarts;
import myCharts from "./utils/myCharts.js";
Vue.use(myCharts);

Vue.config.productionTip = false;

Vue.prototype.localGetItem = function(params) {
  return localStorage.getItem(params);
};
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
Vue.prototype.$moment = moment;



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
