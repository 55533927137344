/**
 * 各种画echarts图表的方法都封装在这里
 * 注意：这里echarts没有采用按需引入的方式，只是为了方便学习
 */
import "echarts/map/js/province/shanxi.js";
import { max } from "moment";
// import { delete } from "vue/types/umd";

var echarts = require("echarts");
const install = function(Vue) {
  Object.defineProperties(Vue.prototype, {
    $chart: {
      get() {
        return {
          //行业管理/基本概况/全省农村供水工程总数/环形图
          ciicle1: function(id, data) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();

            const optionData = {
              color: ["#FFB14B", "#f5f5f5"],
              tooltip: {
                trigger: "item",
                // formatter: "{a} <br/>{b} : {c} ({d}%)"
                formatter: "{b} : ({d}%)"
              },
              series: [
                {
                  name: "",
                  type: "pie",
                  radius: ["70%", "90%"],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: "center"
                  },
                  labelLine: {
                    show: false
                  },
                  data: data
                }
              ]
            };
            this.chart.setOption(optionData);
          },

          // 行业管理/基本概况/规模化供水总数/工程数（处）
          bar1: function(id, data) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let min = "0";
            // let max = "";
            // if (data[0] <= 5 && data[1] <= 5) {
            //   max = 5;
            // } else {
            //   max = Math.max(...data);
            // }
            const optionData = {
              color: ["#FFB14B"],
              // tooltip: {
              //   trigger: "axis",
              //   axisPointer: {
              //     // 坐标轴指示器，坐标轴触发有效
              //     type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
              //   }
              // },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                // formatter: "{a} <br/>{b} : {c}处"
                formatter: "{b} : {c}处"
              },
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  type: "category",
                  data: ["城市管网延伸", "万人工程"],
                  axisLabel: {
                    show: true,
                    textStyle: {
                      fontSize: 10 //更改坐标轴文字大小
                    },
                    interval: 0, //横轴信息全部显示
                    rotate: 40 //-30度角倾斜显示
                  },
                  axisTick: {
                    alignWithLabel: true
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  min: min,
                  // max: max,
                  name: "工程数(处)"
                }
              ],
              series: [
                {
                  name: "工程数(处)",
                  type: "bar",
                  barWidth: "20%",
                  // data: [67, 200]
                  data: data
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          // 行业管理/基本概况/规模化供水总数/人口数（万）
          bar2: function(id, data) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const optionData = {
              color: ["#1890FF"],
              // tooltip: {
              //   trigger: "axis",
              //   axisPointer: {
              //     // 坐标轴指示器，坐标轴触发有效
              //     type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
              //   }
              // },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: "{b} : {c}万人"
              },
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  type: "category",
                  data: ["城市管网延伸", "万人工程"],
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    show: true,
                    textStyle: {
                      fontSize: 10 //更改坐标轴文字大小
                    },
                    interval: 0, //横轴信息全部显示
                    rotate: 40 //-30度角倾斜显示
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  name: "人口数(万)"
                }
              ],
              series: [
                {
                  name: "",
                  type: "bar",
                  barWidth: "20%",
                  // data: [90, 200]
                  data: data
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          //行业管理/基本概况/全省农村供水工程分类/环形图
          ciicle2: function(id, index, datas) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let color1 = [
              "#6DE0E2",
              "#379FFF",
              "#FFAC42",
              "#705EFF",
              "#FF589C"
            ];
            let color2 = [
              "#73bfb1",
              "#1f7ea6",
              "#ef966e",
              "#df5854",
              "#709ed9"
            ];

            const optionData = {
              color: index == 0 ? color1 : color2,
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                // formatter: "{a} <br/>{b} : {c} ({d}%)"
                formatter: "{a} <br/>{b} : ({d}%)"
              },
              legend: {
                top: "bottom",
                left: "center",
                data: datas.title
              },
              series: [
                {
                  name: "",
                  type: "pie",
                  radius: ["45%", "65%"],
                  center: ["50%", "46%"],
                  data: datas.data,
                  itemStyle: {
                    normal: {
                      label: {
                        show: true,
                        formatter: `{b}\n {d}%`,
                        textStyle: {
                          fontSize: 14,
                          fontWeight: "bold"
                        }
                      },
                      labelLine: {
                        length: 10,
                        length2: 10,
                        maxSurfaceAngle: 80,
                        show: true
                      }
                    }
                  }
                }
              ]
            };
            this.chart.setOption(optionData);
          },

          //行业管理/基本概况/全省农村供水工程分类/柱状图
          line1: function(id, index, datas) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let color = [];
            let unit = "";
            let min = "";
            var className = "";
            let max = "";
            // let arrs = 0;
            // arrs = datas.data.reduce((num1, num2) => {
            //   return num1 > num2 ? num1 : num2;
            // });
            if (index == 0) {
              color = ["#1890FF"];
              unit = "单位：万人";
              min = 0;
              // max = arrs;
              className = "万人";
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
            }
            if (index == 1) {
              color = ["#FFAC42"];
              unit = "单位：处";
              min = 0;
              // max = arrs;
              className = "处";
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
            }
            if (index == 2) {
              color = ["#6DE0E2"];
              unit = "单位：%";
              min = 0;
              max = 100;
              var obj = {
                type: "value",
                min: min,
                max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "%";
            }
            if (index == 3) {
              color = ["#FF589C"];
              unit = "单位：%";
              min = 0;
              max = 100;
              var obj = {
                type: "value",
                min: min,
                max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "%";
            }

            const optionData = {
              color: color,
              // tooltip: {
              //   trigger: "axis",
              //   axisPointer: {
              //     // 坐标轴指示器，坐标轴触发有效
              //     type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
              //   }
              // },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: `{b} : {c}${className}`
              },
              xAxis: [
                {
                  type: "category",
                  // data: ["城市管网延伸", "万人", "千人", "千人以下", "分散"],
                  data: datas.title,
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    interval: 0, //横轴信息全部显示
                    rotate: 30 //-30度角倾斜显示
                  }
                }
              ],
              yAxis: [obj],
              series: [
                {
                  name: "",
                  type: "bar",
                  barWidth: "15%",
                  // data: [200, 512, 200, 965, 680]
                  data: datas.data
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          //行业管理/基本概况/
          line2: function(id, datas) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const optionData = {
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function(datas) {
                  var res =
                      datas[0].axisValue +
                      ":" +
                      (Number(datas[0].value) + Number(datas[1].value)).toFixed(
                        2
                      ) +
                      "万人" +
                      "<br/>",
                    val;
                  for (var i = 0, length = datas.length; i < length; i++) {
                    val = datas[i].value;
                    res += datas[i].seriesName + "：" + val + "万人" + "<br/>";
                  }
                  return res;
                }
              },
              legend: {
                top: "bottom",
                left: "center",
                itemWidth: 15,
                itemHeight: 15,
                data: ["集中供水", "分散供水"]
              },
              xAxis: [
                {
                  type: "category",
                  // data: ["太原", "运城", "临汾", "长治", "阳泉", "大同", "晋城", "忻州", "朔州", "吕梁"],
                  data: datas.title,
                  axisTick: {
                    alignWithLabel: true
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  name: "单位：万人",
                  axisLine: {
                    //y轴
                    show: true
                  }
                }
              ],
              series: [
                {
                  name: "集中供水",
                  type: "bar",
                  stack: "使用情况",
                  barWidth: "15%",
                  // data: [5, 20, 36, 10, 10, 20,40, 22, 18, 35, 42, 40],
                  data: datas.data,
                  itemStyle: {
                    normal: { color: "#1890FF" }
                  }
                },
                {
                  name: "分散供水",
                  type: "bar",
                  stack: "使用情况",
                  barWidth: "15%",
                  // data: [40, 22, 18, 35, 5, 20, 36, 10, 10, 42, 40],
                  data: datas.data2,
                  itemStyle: {
                    normal: { color: "#FFAC42" }
                  }
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          //行业管理/基本概况/区域对比/集中供水工程覆盖人口/柱状图
          line3: function(id, index, datas) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let color = [];
            let unit = "";
            let min = "";
            var className = "";
            // let max = "";
            // let arrs = 0;
            // arrs = datas.data.reduce((num1, num2) => {
            //   return num1.value > num2.value ? num1.value : num2.value;
            // });
            if (index == 0) {
              color = ["#1890FF"];
              unit = "单位：万人";
              min = 0;
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "万人";
            }
            if (index == 1) {
              color = ["#FFAC42"];
              unit = "单位：处";
              min = 0;
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "处";
            }
            if (index == 2) {
              color = ["#6DE0E2"];
              unit = "单位：%";
              min = 0;
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "%";
            }
            if (index == 3) {
              color = ["#FF589C"];
              unit = "单位：%";
              min = 0;
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "%";
            }
            const optionData = {
              color: color,
              // tooltip: {
              //   trigger: "axis",
              //   axisPointer: {
              //     // 坐标轴指示器，坐标轴触发有效
              //     type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
              //   }
              // },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: `{b} : {c}${className}`
              },
              xAxis: {
                type: "category",
                // data: ["太原", "运城", "临汾", "长治", "阳泉", "大同", "晋城", "忻州", "朔州", "吕梁"],
                data: datas.title,
                axisTick: {
                  alignWithLabel: true
                },
                axisLabel: {
                  interval: 0, //横轴信息全部显示
                  rotate: -10 //-30度角倾斜显示
                }
              },
              yAxis: [obj],
              series: [
                {
                  name: "",
                  type: "bar",
                  barWidth: "15%",
                  // data: [5, 20, 36, 10, 80, 90,40, 22, 18, 35, 42, 40]
                  data: datas.data
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          //行业管理/四率/集中供水率对比/柱状图
          line4: function(id, index, datas) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let color = [];
            if (index == 0) {
              color = ["#1890FF"];
            }
            if (index == 1) {
              color = ["#FFAC42"];
            }
            if (index == 2) {
              color = ["#6DE0E2"];
            }
            if (index == 3) {
              color = ["#FF589C"];
            }
            const optionData = {
              color: color,
              // tooltip: {
              //   trigger: "axis",
              //   axisPointer: {
              //     // 坐标轴指示器，坐标轴触发有效
              //     type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
              //   }
              // },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: `{b} : {c}%`
              },
              xAxis: [
                {
                  type: "category",
                  data: datas.title,
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    interval: 0, //横轴信息全部显示
                    rotate: -10 //-30度角倾斜显示
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  min: 0,
                  max: 100,
                  name: "单位：%",
                  axisLine: {
                    //y轴
                    show: true
                  }
                }
              ],
              series: [
                {
                  name: "",
                  type: "bar",
                  barWidth: "15%",
                  data: datas.data
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          //  地图1
          initChinaMap: function(id, province, datas) {
            if (province.indexOf("省") > -1 || province.indexOf("市") > -1) {
              province = province.substring(0, province.length - 1);
            }
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const mapOption = {
              tooltip: {
                trigger: "item"
              },
              legend: {
                orient: "vertical",
                x: "left",
                data: ["iphoneX"]
              },
              dataRange: {
                show: false,
                min: 0,
                max: 100,
                x: "left",
                y: "center",
                text: ["max", "min", "11"],
                inRange: {
                  color: ["#2B6041", "#E0EFE3"]
                }
              },
              series: [
                {
                  name: "",
                  type: "map",
                  mapType: province,
                  roam: false,
                  label: {
                    normal: {
                      show: true
                    },
                    emphasis: {
                      areaColor: "#2B6041",
                      textStyle: {
                        color: "#fff"
                      }
                    }
                  },
                  itemStyle: {
                    normal: {
                      label: { show: true },
                      borderColor: "#ffffff",
                      areaColor: "#E0EFE3",
                      borderWidth: 1
                    },
                    emphasis: {
                      label: { show: true },
                      areaColor: "#2B6041",
                      borderWidth: 0
                    }
                  },
                  data: datas
                }
              ]
            };
            this.chart.setOption(mapOption);
          },
          //水质管理/水质概况/柱状图
          line5: function(id, index, datas) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let color = [];
            if (index == 0) {
              color = ["#1890FF"];
            }
            if (index == 1) {
              color = ["#FFAC42"];
            }
            if (index == 2) {
              color = ["#6DE0E2"];
            }
            if (index == 3) {
              color = ["#FF589C"];
            }
            const optionData = {
              color: color,
              // tooltip: {
              //   trigger: "axis",
              //   axisPointer: {
              //     // 坐标轴指示器，坐标轴触发有效
              //     type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
              //   }
              // },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: `{b} : {c}%`
              },
              xAxis: [
                {
                  type: "category",
                  // data: [
                  //   "太原",
                  //   "运城",
                  //   "临汾",
                  //   "长治",
                  //   "阳泉",
                  //   "大同",
                  //   "晋城",
                  //   "忻州",
                  //   "朔州",
                  //   "吕梁"
                  // ],
                  data: datas.title,
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    interval: 0, //横轴信息全部显示
                    rotate: -10 //-30度角倾斜显示
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  min: "0",
                  max: "100",
                  name: "单位：%",
                  axisLine: {
                    //y轴
                    show: true
                  }
                }
              ],
              series: [
                {
                  name: "",
                  type: "bar",
                  barWidth: "15%",
                  // data: [5, 20, 36, 10, 80, 90, 40, 22, 18, 35, 42, 100]
                  data: datas.data
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          //超标分析/超标指标分析/柱状图
          line6: function(id, index, datas) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let color = [];
            let unit = "";
            let min = "";
            var className = "";
            // let max = "";
            // let arrs = 0;
            // arrs = datas.data.reduce((num1, num2) => {
            //   return num1 > num2 ? num1 : num2;
            // });
            if (index == 0) {
              color = ["#50BA50"];
              unit = "单位：万人";
              min = 0;
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "万人";
            }
            if (index == 1) {
              color = ["#FFAC42"];
              unit = "单位：处";
              min = 0;
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "处";
            }
            if (index == 2) {
              color = ["#705EFF"];
              unit = "单位：%";
              min = 0;
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "%";
            }
            if (index == 3) {
              color = ["#FF589C"];
              unit = "单位：%";
              min = 0;
              var obj = {
                type: "value",
                min: min,
                // max: max,
                name: unit,
                axisLine: {
                  //y轴
                  show: true
                }
              };
              className = "%";
            }
            const optionData = {
              color: color,
              // tooltip: {
              //   trigger: "axis",
              //   axisPointer: {
              //     // 坐标轴指示器，坐标轴触发有效
              //     type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
              //   }
              // },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: `{b} : {c}${className}`
              },
              xAxis: [
                {
                  type: "category",
                  data: datas.title,
                  axisTick: {
                    alignWithLabel: true
                  }
                }
              ],
              yAxis: [obj],
              series: [
                {
                  name: "",
                  type: "bar",
                  barWidth: "15%",
                  data: datas.data
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          //  地图2颜色
          initShanxiMap: function(id, province, datas, colors) {
            if (province.indexOf("省") > -1 || province.indexOf("市") > -1) {
              province = province.substring(0, province.length - 1);
            }
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const mapOption = {
              tooltip: {
                trigger: "item"
              },
              legend: {
                orient: "vertical",
                x: "left",
                data: ["iphoneX"]
              },
              dataRange: {
                show: false,
                min: 0,
                max: 10,
                x: "left",
                y: "center",
                text: ["max", "min", "11"],
                inRange: {
                  color: [
                    "#F0D9B9",
                    "#BADEEA",
                    "#C3F6BD",
                    "#D2E4CA",
                    "#CFB2B4",
                    "#B6BAD5"
                  ]
                  // colors
                }
              },
              series: [
                {
                  name: "",
                  type: "map",

                  mapType: province,
                  roam: false,
                  label: {
                    normal: {
                      show: true
                    },
                    emphasis: {
                      areaColor: "#2B6041",
                      textStyle: {
                        color: "#fff"
                      }
                    }
                  },
                  itemStyle: {
                    normal: {
                      label: { show: true },
                      borderColor: "#ffffff",
                      areaColor: "#E0EFE3",
                      borderWidth: 1
                    },
                    emphasis: {
                      label: { show: true },
                      areaColor: "#2B6041",
                      borderWidth: 0
                    }
                  },
                  data: [
                    { name: "太原市", value: 0 },
                    { name: "大同市", value: 0 },
                    { name: "阳泉市", value: 2 },
                    { name: "长治市", value: 2 },
                    { name: "晋城市", value: 4 },
                    { name: "朔州市", value: 4 },
                    { name: "晋中市", value: 6 },
                    { name: "运城市", value: 6 },
                    { name: "忻州市", value: 8 },
                    { name: "临汾市", value: 8 },
                    { name: "吕梁市", value: 10 }
                  ]
                  //data: datas
                }
              ]
            };
            this.chart.setOption(mapOption);
          },
          //超标分析/各市主要超标指标/柱状图
          line7: function(id, index, datas) {
            console.log(datas);
            // datas.reduce((num1, num2) => {
            //   return num1.value > num2.value ? num1.value : num2.value;
            // });
            console.log("最大值1111");
            let maxs = Math.max.apply(
              Math,
              datas.data.map(item => {
                return item.value;
              })
            );
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let color = [];
            if (index == 0) {
              color = ["#1890FF"];
            }
            if (index == 1) {
              color = ["#FFAC42"];
            }
            if (index == 2) {
              color = ["#6DE0E2"];
            }
            if (index == 3) {
              color = ["#FF589C"];
            }
            const optionData = {
              color: color,
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function(datas) {
                  var res = datas[0].axisValue + "<br/>",
                    val;
                  for (var i = 0, length = datas.length; i < length; i++) {
                    val = datas[i].value;
                    res += datas[0].name + "：" + val + "处" + "<br/>";
                  }
                  return res;
                }
              },
              xAxis: [
                {
                  type: "category",
                  data: datas.title,
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    interval: 0, //横轴信息全部显示
                    rotate: -10 //-30度角倾斜显示
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  min: 0,
                  // max: maxs * 2,
                  max: 18,

                  name: "单位：处",
                  axisLine: {
                    //y轴
                    show: true
                  }
                }
              ],
              series: [
                {
                  name: "",
                  type: "bar",
                  barWidth: "15%",
                  data: datas.data
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          //geojson
          initGeojson: function(id, geojson, datas) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            echarts.registerMap("GEO", geojson);
            this.chart.setOption({
              grid: {},
              tooltip: {
                trigger: "item",
                // formatter: '{b}<br/>{c}'
                formatter(params) {
                  let data = params.data;
                  if (!data) {
                    return "";
                  }
                  return `${data.name}<br/>水质达标率：${data.value}<br/>超标指标：${data.detectName}`;
                }
              },
              // toolbox: {
              //   show: true,
              //   orient: 'vertical',
              //   left: 'right',
              //   top: 'center',
              //   feature: {
              //     dataView: {readOnly: false},
              //     restore: {},
              //     saveAsImage: {}
              //   }
              // },
              // visualMap: {
              //   min: 800,
              //   max: 50000,
              //   text: ['High', 'Low'],
              //   realtime: false,
              //   calculable: true,
              //   inRange: {
              //     color: ['lightskyblue', 'yellow', 'orangered']
              //   }
              // },
              // dataRange: {
              //   show: true,
              //   min: 0,
              //   max: 100,
              //   x: "left",
              //   y: 50,
              //   bottom: 50,
              //   text: ["100", "0"],
              //   inRange: {
              //     color: ["#E0EFE3","#19be6b"]
              //   }
              // },
              visualMap: {
                min: 0,
                max: 100,
                text: ["100", "0"],
                realtime: false,
                calculable: false,
                inRange: {
                  color: ["#E0EFE3", "#19be6b"]
                }
              },
              series: [
                {
                  name: "",
                  type: "map",
                  mapType: "GEO",
                  roam: false,
                  label: {
                    normal: {
                      show: true
                    },
                    emphasis: {
                      // areaColor: "#2B6041",
                      textStyle: {
                        color: "#2B6041"
                      }
                    }
                  },
                  itemStyle: {
                    normal: {
                      label: { show: true },
                      borderColor: "#ffffff",
                      // areaColor: "#E0EFE3",
                      borderWidth: 1
                    },
                    emphasis: {
                      label: { show: true },
                      // areaColor: "#2B6041",
                      borderWidth: 1,
                      // borderColor:'#ea9a56'
                      areaColor: "#2db7f5"
                    }
                  },
                  data: datas
                }
              ]
            });
          },


          //--------by 杜珊  start--------

          //超标指标合格率---柱状图
          lineRate: function(id, index, datas) {
            console.log(datas);
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let color = [];
            let xData = [];
            let seriesWater = [];
            let seriesSample = [];
            datas.forEach((item,k) => {
              xData.push(item.adName);
              seriesWater.push(item.goodPow);
              seriesSample.push(item.goodPeoPow);
            })
            if (index == 0) {
              color = ["#1890FF"];
            }
            if (index == 1) {
              color = ["#FFAC42"];
            }
            if (index == 2) {
              color = ["#6DE0E2"];
            }
            if (index == 3) {
              color = ["#FF589C"];
            }
            const optionData = {
              color: color,
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter(params) {
                  let data = params;
                  if (data.length>1) {
                    return `${data[0].name}<br/>${data[0].seriesName}:${data[0].data!=null?data[0].data:""}%<br/>${data[1].seriesName}:${data[1].data!=null?data[1].data:""}%`;
                  }else{
                    return `${data[0].name}<br/>${data[0].seriesName}:${data[0].data!=null?data[0].data:""}%`;
                  }
                }
              },
              legend: {//图例组件，颜色和名字
                right:'center',
                top:'96%',
                itemGap: 16,
                itemWidth: 18,
                itemHeight: 10,
                data:[{
                  name:'水样合格率',
                },
                  {
                    name:'合格水样覆盖人口占比',
                  }],
                textStyle: {
                  color: '#333',
                  fontStyle: 'normal',
                  fontFamily: '微软雅黑',
                  fontSize: 12,
                }
              },
              xAxis: [
                {
                  type: "category",
                  data: xData,
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    interval: 0, //横轴信息全部显示
                    rotate: -10 //-30度角倾斜显示
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  min: 0,
                  max:function(value){
                    return Math.ceil(value.max);
                  },

                  name: "单位：%",
                  minInterval: 1,
                  axisLine: {
                    //y轴
                    show: true
                  }
                }
              ],
              series: [
                {
                  name: "水样合格率",
                  type: "bar",
                  barWidth: "15%",
                  data: seriesWater,
                  itemStyle: {
                    normal: {
                      show: true,
                      color: '#6eb3fe'
                    }
                  },
                },
                {
                  name: "合格水样覆盖人口占比",
                  type: "bar",
                  barWidth: "15%",
                  data: seriesSample,
                  itemStyle: {
                    normal: {
                      show: true,
                      color: '#f6b559'
                    }
                  },
                }
              ]
            };
            this.chart.setOption(optionData);
          },

          //超标指标数量---柱状图
          lineNum: function(id, index, datas, sortNum, tabTypeInner) {
            console.log(datas);
            let data = [];
            let series_title = '';
            let unit = '';
            let xData = [];
            let seriesWater = [];
            let color = '#1890FF';
            datas.forEach((item,k) => {
              if(tabTypeInner == 0){
                let obj={
                  adName:item.adName,
                  badSampleNum: item.badSampleNum
                }
                data.push(obj);
              }else{
                let obj={
                  adName:item.adName,
                  badCoverPeo: item.badCoverPeo
                }
                data.push(obj);
              }
            })
            if(tabTypeInner == 0){
              series_title = '超标水样数';
              unit = '件'
              color = '#1890FF'
              //数据排序
              data = sortNum? data.sort(function(a,b){return b.badSampleNum-a.badSampleNum}):data.sort(function(a,b){return a.badSampleNum-b.badSampleNum})
              data.forEach((item,k) => {
                xData.push(item.adName);
                seriesWater.push(item.badSampleNum)
              })
            }else{
              series_title = '超标水样覆盖人口'
              unit = '万人';
              color = '#f6b559'
              //数据排序
              data = sortNum? data.sort(function(a,b){return b.badCoverPeo-a.badCoverPeo}):data.sort(function(a,b){return a.badCoverPeo-b.badCoverPeo})
              data.forEach((item,k) => {
                xData.push(item.adName);
                seriesWater.push(item.badCoverPeo)
              })
            }
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const optionData = {
              color: color,
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  // 坐标轴指示器，坐标轴触发有效
                  type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter:'{b0}<br />{a0}:{c0}'+unit,
              },
              xAxis: [
                {
                  type: "category",
                  data: xData,
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    interval: 0, //横轴信息全部显示
                    rotate: -10 //-30度角倾斜显示
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  min: 0,
                  max:function(value){
                    return Math.ceil(value.max);
                  },

                  name: "单位："+unit,
                  minInterval: 1,
                  axisLine: {
                    //y轴
                    show: true
                  }
                }
              ],
              series: [
                {
                  name: series_title,
                  type: "bar",
                  barWidth: "15%",
                  data: seriesWater
                }
              ]
            };
            this.chart.setOption(optionData);
          },
          sortNumber(a,b) {return a - b}

          //--------by 杜珊  end--------
        };
      }
    }
  });
};

export default {
  install
};
