<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>
<script>
  import { i18nRender } from '@/locales'
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

  export default {
    data () {
      return {
        locale: zhCN,
      }
    },
  }
</script>

<style lang="scss">
/* 文字超出变点 */
.text-overflow1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-overflow2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}

.none {
  display: none;
}

.block {
  display: block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

input::-webkit-input-placeholder {
  color: #ccc;
}
input::-moz-input-placeholder {
  color: #ccc;
}
input::-ms-input-placeholder {
  color: #ccc;
}
</style>
<style>
.ant-layout-content {
  background: transparent;
  padding: 0 !important;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
  margin-bottom: 0;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}
#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.table {
  margin-top: 30px;
}
</style>
